import React from "react"
import "./area3.css";

import Background from '../../images/service/bg_area3.jpg';
import Title from '../../images/service/txt_seminar.png';
import Icon from '../../images/top/icon_1.png';

const ServiceArea3 = () => (
  <section className="area3">
    <div className="title">
      <p><img alt="Background" src={Background} className="scale-to-fit" /></p>
      <p className="txt"><img alt="セミナー" src={Title} className="scale-to-fit" /></p>
    </div>
    <div className="description">
      <div className="head">
        <p><img alt="icon" src={Icon} className="scale-to-fit" /></p>
        <div className="text">
          <p className="sub">サービス内容</p>
          <h1 className="ff-min">SERVICE</h1>
        </div>
        <p><img alt="icon" src={Icon} className="scale-to-fit" /></p>
      </div>
      <ul className="body">
        <li>
          おなまえメソッド
          <span className="registerd-sign">&#174;</span>
          は、1DAYトレーニング、2種類の認定とおなまえメソッド
          <span className="registerd-sign">&#174;</span>
          公認講師、そして自分自身を深く知って自分に惚れてしまうトレーニングから起業家として独立を目指す、全てのお客様にサービスを提供しています！
       </li>
      </ul>
    </div>
  </section>
);

export default ServiceArea3;
