import React from "react"
import "./area2.css";

import Scholar_1 from '../../images/top/scholar_1.png';
import Scholar_2 from '../../images/top/scholar_2.png';
import Scholar_3 from '../../images/top/scholar_3.png';
import Scholar_4 from '../../images/top/scholar_4.png';

const ServiceArea2 = () => (
  <section className="area2">
    <div className="title-box ff-min">
      <h2>自分を磨く、未来を磨く</h2>
      <h3>自分の世界にときめき続ける人生を</h3>
    </div>
    <ul className="academic-basis">
      <li><img alt="PHYCOLOGY" src={Scholar_1} className="scale-to-fit" /></li>
      <li><img alt="BRAIN SCIENCE" src={Scholar_2} className="scale-to-fit" /></li>
      <li><img alt="SOCIOLOGY" src={Scholar_3} className="scale-to-fit" /></li>
      <li><img alt="LINGUISTICS" src={Scholar_4} className="scale-to-fit" /></li>
    </ul>
  </section>
);

export default ServiceArea2;
