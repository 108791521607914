import React from "react"
import "./photo.css";

import Photo_session from '../../images/service/photo_session.jpg'
import Btn_pdf from '../../images/service/btn_pdf.png'
import Worksheet from '../../images/service/worksheet.pdf'

const Photo = () => (
  <section className="photo">
    <div>
      <a href={Worksheet} target="_blank" rel="noreferrer">
        <img alt="Free Work Seat" src={Btn_pdf} className="scale-to-fit" />
      </a>
    </div>
    <div><img alt="Session" src={Photo_session} className="scale-to-fit" /></div>
  </section>
);

export default Photo;
