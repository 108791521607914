import React from "react"
import { Link } from "gatsby"
import "./content.css";

const Session = () => (
  <section className="content personal">
    <div className="step">
      <p>Jump step</p>
    </div>
    <ul className="catch ff-min">
      <li>
        <p>
          おなまえメソッド
          <span className="registerd-sign">&#174;</span>
        </p>
        <p>
          全てのトレーニングや
        </p>
      </li>
      <li>
        <span>＼</span>
        <p>
          認定講座を開催して
        </p>
        <p>
          起業や独立を目指したい
        </p>
        <span>／</span>
      </li>
    </ul>
    <p className="arrow">&darr;</p>
    <div className="main">
      <p className="ribon">PERSONAL</p>
      <div className="title">
        <h2>PERSONAL SESSION</h2>
        <h1 className="ff-min">
          公認講師養成講座
        </h1>
      </div>
      <div className="body">
        <div className="panel">
          <h1 className="ff-min">
            おなまえメソッド
            <span className="registerd-sign">&#174;</span>
            <br />
            公認講師講座
          </h1>
          <p className="catch">
            創始者星名ミリーが<br />直接お伝えする養成講座です
          </p>
          <ul>
            <li>考え方が軽やかになりスルスル人生がうまくいく！</li>
            <li>これまでの学びのまとめを実践的に落とし込んでいきます</li>
            <li>
              「おなまえメソッド
              <span className="registerd-sign">&#174;</span>
              」全てのトレーニングや<br />認定講座を開催出来ます
            </li>
          </ul>
          <p className="detail">
            「あなたのおかげで人生が楽しく自分が大好きになりました」と言われる側になり、輝く人を増やし、この世界を輝かせてください。<br />
            一緒に「名前が理想を叶える」を体現していきましょう。
          </p>
        </div>
        <div className="navigations">
          <div className="btn-holder">
            <Link to='/detail2'>講座詳細はこちら</Link>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Session;
