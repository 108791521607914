import React, { useState } from "react"
import { Link } from "gatsby"
import "./content.css";

import TxtSession from '../../images/service/txt_session.png';
import Select from './select_member';

const Personal = () => {
  const [ visibleoverlay, setVisibleoverlay ] = useState(false);

  const handleSelectMember = () => {
    setVisibleoverlay(true);
  }

  const handleClose = () => {
    setVisibleoverlay(false);
  }

  return (
    <section className="content personal">
      <ul className="catch ff-min">
        <li>まずは自分自身の名前の</li>
        <li>
          <span>＼</span>
          個人セッションを受けてみたい
          <span>／</span>
        </li>
      </ul>
      <p className="arrow">&darr;</p>
      <div className="main">
        <p className="ribon">PERSONAL</p>
        <div className="title">
          <p className="txt-icon">
            <img alt="Session" src={TxtSession} className="scale-to-fit" />
          </p>
          <h2>PERSONAL SESSION</h2>
          <h1 className="ff-min">名前個人鑑定</h1>
        </div>
        <div className="body">
          <div className="panel">
            <h2>トライアル名前個人鑑定</h2>
            <ul>
              <li>名前でどんな事が分かるの？</li>
              <li>私の名前にはどんな意味を持っているの？</li>
            </ul>
            <p className="detail">
              まずは自分自身の事をもっと知りたいあなたにむけたトライアルメニュー。
              おなまえ
              <span className="registerd-sign">&#174;</span>
              トレーナーを目指す方にもオススメです。
            </p>
            <div className="fee">
              料金 30分 3300円（税込）
            </div>
          </div>
          <div className="navigations">
            <div className="btn-holder">
              <span role="button" tabIndex={0} onClick={handleSelectMember} onKeyDown={handleSelectMember}>講師を選んで申し込む</span>
            </div>
            <div className="btn-holder">
              <Link to='/specialmenu'>星名ミリーの特別メニューはこちら</Link>
            </div>
          </div>
        </div>
      </div>
      { visibleoverlay ?
        <Select close={handleClose} />
        :
        null
      }
    </section>
  )
}

export default Personal;
