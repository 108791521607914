import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Area1 from "../components/service/area1";
import Area2 from "../components/service/area2";
import Area3 from "../components/service/area3";
import Area4 from "../components/service/area4";
import Works from "../components/common/works" 
import Categories from "../components/common/categories" 
import Personal from "../components/service/personal";
import Training from "../components/service/training";
import Lesson from "../components/service/lesson";
import Session from "../components/service/session";
import Photo from "../components/service/photo";
import ExplanatoryTextGift from "../components/common/explanatoryTextGift";

const ServicePage = () => (
  <Layout>
    <SEO title="SERVICE" />
    <div className="service">
      <Area1 />
      <Area2 />
      <Area3 />
      <Area4 />
      <Works />
      <Categories />
      <Personal />
      <Training />
      <Lesson />
      <Session />
      <Photo />
      <ExplanatoryTextGift />
    </div>
  </Layout>
)

export default ServicePage
