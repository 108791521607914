import React from "react"
import { Link } from "gatsby"
import "./content.css";

import TxtCourse from '../../images/service/txt_course.png'

const Lesson = () => (
  <section className="content personal">
    <div className="step">
      <p>Stage up</p>
    </div>
    <ul className="catch ff-min">
      <li>
        <p>
          おなまえ
          <span className="registerd-sign">&#174;</span>
          トレーナー、
        </p>
        <p>
          おなまえ
          <span className="registerd-sign">&#174;</span>
          分析士として
        </p>
      </li>
      <li>
        <span>＼</span>
        <p>
          1DAYワークショップや
        </p>
        <p>
          鑑定を出来るようになりたい
        </p>
        <span>／</span>
      </li>
    </ul>
    <p className="arrow">&darr;</p>
    <div className="main">
      <p className="ribon">COURSE</p>
      <div className="title">
        <p className="txt-icon">
          <img alt="Course" src={TxtCourse} className="scale-to-fit" />
        </p>
        <h2>LESSON COURSE</h2>
        <h1 className="ff-min">
          おなまえメソッド
          <span className="registerd-sign">&#174;</span>
          認定講座
        </h1>
      </div>
      <div className="body">
        <div className="panel">
          <h1 className="ff-min">
            おなまえ
            <span className="registerd-sign">&#174;</span>
            トレーナー認定講座
          </h1>
          <p className="catch">
            名前で本来の姿で、生き方・考え方を深く学び<br />伝える側にもなれる認定講座です
          </p>
          <ul>
            <li>名前の知識を更に深めて、講座を開けるようになりたい</li>
            <li>
              おなまえ
              <span className="registerd-sign">&#174;</span>
              トレーナーとして人の役に立ちたい
            </li>
            <li>私らしさを大切にして自分に惚れる人を増やしたい</li>
          </ul>
          <p className="detail">名前でのカウンセリング・ヒーリング・コーチング法を取得していきます！初めての方でも活動して頂きやすい様にカリキュラムを組んでいるのでご安心ください。</p>
        </div>
        <div className="navigations">
          <div className="btn-holder">
            <Link to='/detail1'>講座詳細はこちら</Link>
          </div>
        </div>
        <div className="panel">
          <h1 className="ff-min">
            おなまえ
            <span className="registerd-sign">&#174;</span>
            分析士認定講座
          </h1>
          <p className="catch">名前に秘められた暗号を分析し<br />ネームプロファイル（鑑定）手法を取得する認定講座です</p>
          <p className="detail">
            名前1つで自分自身のことを紐解いていく手法、<br />おなまえ
            <span className="registerd-sign">&#174;</span>
            アナグラム上級とネームプロファイリングを<br />「おなまえ
            <span className="registerd-sign">&#174;</span>
            メソッド」を通して学びます。
          </p>
          <ul>
            <li>人生のシナリオを読み解き豊かな人を増やした</li>
            <li>悩みを解決して自信がつくお手伝いがしたい</li>
            <li>
              おなまえ
              <span className="registerd-sign">&#174;</span>
              分析士鑑定講師として社会に貢献したい
            </li>
          </ul>
        </div>
        <div className="navigations">
          <div className="btn-holder">
            <Link to='/detail1'>講座詳細はこちら</Link>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Lesson;
