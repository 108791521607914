import React from "react"
import "./area1.css";

import BrandSign from '../../images/service/brand_sign.png'
import Diamond from '../../images/service/diamond2.jpg'
import PointLeft from '../../images/service/point_left.png'

const ServiceTop = () => (
  <section className="area1">
    <div className="sign">
      <img alt=" " src={BrandSign} className="scale-to-fit" />
    </div>
    <h1 className="ff-min">
      おなまえメソッド
      <span className="registerd-sign">&#174;</span>
      とは
    </h1>
    <div className="diamond">
      <img alt=" " src={Diamond} className="scale-to-fit" />
    </div>
    <div className="read ff-min">
      誰でも持っている「名前」を姓名判断という枠を超え心理学、脳科学、音、並び、表記、アナグラムというあらゆる角度で分析し、子供から大人まで誰でも簡単に自分自身を知り、人生に活かせるチャンスを生み出す事が出来る様になるメソッドです。
    </div>
    <div className="point">
      <img alt=" " src={PointLeft} className="scale-to-fit" />
    </div>
  </section>
);

export default ServiceTop;
