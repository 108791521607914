import React from "react"
import "./area4.css";

import Firststep from '../../images/service/txt_firststep.png'
import Stageup from '../../images/service/txt_stageup.png'
import Jumpup from '../../images/service/txt_jumpup.png'

const ServiceArea4 = () => (
  <section className="area4">
    <div className="step">
      <div className="panel full">
        <div className="ribon ff-min">1DAYトレーニング入門</div>
        <div className="body">
          <h3>
            <span>＼</span>
            初心者さんへ
            <span>／</span>
          </h3>
          <div className="sign">
            <img alt="First Step" src={Firststep} className="scale-to-fit" />
          </div>
          <ul>
            <li>
              おなまえ
              <span className="registerd-sign">&#174;</span>
              入門トレーニング
            </li>
            <li>
              おなまえ
              <span className="registerd-sign">&#174;</span>
              分析トレーニング
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div className="arrow"><span>&#8594;</span></div>
    <div className="step">
      <div className="panel short upper">
        <div className="ribon ff-min">認定講座</div>
        <div className="body">
          <h3>
            <span>＼</span>
            中級者さんへ
            <span>／</span>
          </h3>
          <div className="sign">
            <img alt="Stage up!" src={Stageup} className="scale-to-fit" />
          </div>
          <ul>
            <li>
              おなまえ
              <span className="registerd-sign">&#174;</span>
              トレーナー
            </li>
          </ul>
        </div>
      </div>
      <div className="panel short">
        <div className="body">
          <h3>
            <span>＼</span>
            中級者さんへ
            <span>／</span>
          </h3>
          <div className="sign">
            <img alt="Stage up!" src={Stageup} className="scale-to-fit" />
          </div>
          <ul>
            <li>
              おなまえ
              <span className="registerd-sign">&#174;</span>
              分析士
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div className="arrow"><span>&#8594;</span></div>
    <div className="step">
      <div className="panel full">
        <div className="ribon ff-min">起業向け養成講座</div>
        <div className="body">
          <h3>
            <span>＼</span>
            上級者さんへ
            <span>／</span>
          </h3>
          <div className="sign">
            <img alt="Jump up!" src={Jumpup} className="scale-to-fit" />
          </div>
          <ul>
            <li>
              おなまえメソッド
              <span className="registerd-sign">&#174;</span>
              公認講師
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
);

export default ServiceArea4;
