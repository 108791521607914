import React from "react"
import { Link } from "gatsby"
import "./content.css";

import TxtCourse from '../../images/service/txt_course.png'

const Training = () => (
  <section className="content personal">
    <div className="step">
      <p>First step</p>
    </div>
    <ul className="catch ff-min">
      <li>
        <span>＼</span>
        1DAYワークショップを受講したい
        <span>／</span>
      </li>
    </ul>
    <p className="arrow">&darr;</p>
    <div className="main">
      <p className="ribon">COURSE</p>
      <div className="title">
        <p className="txt-icon">
          <img alt="Course" src={TxtCourse} className="scale-to-fit" />
        </p>
        <h2>TRAINING</h2>
        <h1 className="ff-min">
          おなまえ
          <span className="registerd-sign">&#174;</span>
          トレーニング
        </h1>
      </div>
      <div className="body">
        <div className="panel">
          <h1 className="ff-min">
            おなまえ
            <span className="registerd-sign">&#174;</span>
            入門トレーニング
          </h1>
          <p className="catch">
            <span>名前を「書く」「呼ぶ」「見る」の3ステップで</span>
            <span>自分らしさを知る</span>
          </p>
          <div className="info">
            <p>少人数グループ90分レッスン</p>
            <p>参加費 : 5,500円（税込）</p>
            <p>＜特典 : 5DAYS動画配信 / 添削フォロー会＞</p>
          </div>
          <ul>
            <li>自分の今の本音が分かる</li>
            <li>思考の書き換えができる様になる</li>
            <li>自分のルーツを知る事が出来る</li>
          </ul>
        </div>
        <div className="navigations">
          <div className="btn-holder">
            <Link to='/detail3'>講座詳細はこちら</Link>
          </div>
        </div>
        <div className="panel">
          <h1 className="ff-min">
            おなまえ
            <span className="registerd-sign">&#174;</span>
            分析トレーニング
          </h1>
          <p className="catch">
            名前を3カテゴライズする事で<br />私だけのシナリオを知る
          </p>
          <div className="info">
            <p>少人数グループ120分レッスン</p>
            <p>参加費 : 8,800円（税込）</p>
            <p>
              ＜特典 : おなまえ
              <span className="registerd-sign">&#174;</span>
              ワードBOOK / 添削フォロー会＞
            </p>
          </div>
          <ul>
            <li>音と並びで人生が分かる様になる</li>
            <li>人生のシナリオを読み解ける様になる</li>
            <li>自分の活かし方が分かる様になる</li>
          </ul>
        </div>
        <div className="navigations">
          <div className="btn-holder">
            <Link to='/detail4'>講座詳細はこちら</Link>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Training;
